// ion-alert (https://ionicframework.com/docs/api/alert)
// ion alert is used in many places throughout CCM, PCM, and CGM.
// It's also the default popup box for ion-select components
// This file is an override file to change the default ion-alert styles to more closely match our XD visual specs
// These classes should NOT be used on there own, as they come standard with <ion-alert>
// Most of these styles are overriding or resetting the styles that come out of the box with Ionic's ion-alert
.alert-wrapper.alert-wrapper {
    // ion recommeds upping the specificity for customizing alert styles, which is why everything is wrapped in "double class power" (i.e. .alert-wrapper.alert-wrapper). This is more specific than one class, but not overly powerfull like using !important or an #id selector.
    width: 80%;
    min-width: auto;
    max-width: 400px;
    width: calc(100% - 2rem);
    @media (min-width: 350px) {
        width: calc(100% - 4rem);
    }
    //alert header
    .alert-head {
        background-color: var(--color-primary-light);
        padding: 1rem 1rem;
        box-shadow: var(--shadow-sm);
        z-index: 1; // edge-case: when there isn't an alert message, radio buttons, or body (just a header and footer buttons) ensure the footer's upward shadow is hidden behind the header
    }
    .alert-title {
        margin-top: 0;
        font-size: 1.25rem;
        line-height: 1rem;
        font-weight: 900;
    }
    // alert message (body)
    .alert-message {
        font-size: 1rem;
        line-height: 1.5;
        padding: 1.5rem;
        max-height: 65vh; // this will accommodate wordier alerts to allow it to extend most of the viewport, but leave room for the header and the buttons footer
        &:empty {
            padding: 0; // if nothing inside of alert message we don't want it taking up any space
        }
    }

    // alert radio buttons
    .alert-radio-group {
        border-top: 0;
        padding: 1.5rem;
    }
    .alert-radio-button {
        .alert-button-inner {
            height: auto;
        }
    }
    .alert-radio-icon {
        order: 0;
        -webkit-appearance: none;
        height: 24px;
        width: 24px;
        min-width: 24px;
        border: 3px solid var(--color-border, black);
        border-radius: 24px;
        margin-right: 1rem;
        cursor: pointer;

        &:focus {
            outline: none;
            border-color: var(--color-primary);
        }
    }
    .alert-radio-label {
        padding: 0;
        font-size: 1rem;
        line-height: 1.1875;
        color: var(--black);
    }
    // radio button selected state
    [aria-checked=true] {
        .alert-radio-icon {
            border-color: var(--color-primary);
            background-image: radial-gradient(at 50% 50%, var(--color-primary) 50%, transparent 52%);
        }
        // remove ionic's check box for radio buttons inside ion-alert
        .alert-radio-inner {
            width: 0;
            height: 0;
            display: none;
        }
        .alert-radio-label {
            color: var(--black);
        }
    }

    // alert footer / bottom buttons
    .alert-button-group {
        @include c_buttons;
        justify-content: space-between;
        padding: .6875rem .5rem;
        border: none;
        box-shadow: var(--shadow-xs-top);
    }
    .alert-button {
        border: none;
        flex: none;
        @include c_button;
        --button-width: 0%;
        margin: 0;
        padding: 1px 3px;
        max-width: 50%;
        max-width: calc(50% - 1px);
        min-width: 45%;
        width: auto;
        .alert-button-inner {
            display: block;
            height: auto;
            font-weight: normal;
            overflow-wrap: break-word;
            word-break: break-word;
        }
        &:last-child {
            margin: 0 auto; // if only one button in footer, need to center it with auto left/right margins
        }
        @at-root .alert-wrapper.alert-wrapper .alert-button + .alert-button {
            // @at-root is a sass feature to remove the selector from the nesting tree as if there wasn't any nesting
            margin: 0; // if more than one button, override the last-child above and remove left/right margin centering
        }
    }
}


/*
    Styling rules for ion-alert patient update notifications - assign via the cssClass attribute when creating a new alert
    per: https://xd.adobe.com/view/6116bb9a-883b-4b9c-4308-5f5b0e3fae32-1727/screen/604ea193-dc97-42ad-92ba-4d24257f6ab8
    this.alertCtrl.create({header: `Header Title`, subHeader: `Sub Header Title`, cssClass: `c_ion_alert--update_notification`})
 */
.c_ion_alert--update_notification {
    .alert-wrapper.alert-wrapper {
        position: absolute;
        padding-top: 1rem;
        font-size: 1rem;
        width: 95%;
        min-width: 95%;
        min-height: 30%;
        top: 2rem;
        background-color: var(--color-accent-light);

        .alert-head {
            background-color: transparent;
            box-shadow: unset;
        }

        .alert-head .alert-title {
            font-size: 1.5rem;
            margin-bottom: .75rem;
        }

        .alert-head .alert-sub-title {
            color: var(--black);
            font-size: 1.25rem;
            font-weight: 400;
        }

        .alert-message {
            padding: 1rem;
            font-size: 1.25rem;
            display: flex;
            flex-direction: column;

            .c_ion--alert_list-item {
                display: inline-flex;
                padding-left: 0.5rem;
                text-align: start;

                .item_signifier {
                    margin-right: .5em;
                    filter: brightness(0);
                }

                .item_large {
                    margin-bottom: .25em;
                }
            }
        }

        .alert-button-group {
            box-shadow: unset;
            max-height: 1rem;

            .alert-button {
                background-color: unset;
                box-shadow: unset;
                border: none;
            }

            .c_ion_alert--cancel_button {
                position: absolute;
                top: 0;
                right: 0;
                width: 25%;
                height: 25%;
                z-index: 100;

                .alert-button-inner:before {
                    position: absolute;
                    display: block;
                    top: .75rem;
                    right: 0;
                    width: 3rem;
                    height: 3rem;
                    content: '\2A2F';
                    font-size: 2.75rem;
                    color: var(--black);
                }
            }
        }
    }
}

.c_ion_alert {
    &--notification_status-change {
        .alert-wrapper.alert-wrapper {
            .alert-message {
                font-size: 1.25rem;
                padding: 1rem;
            }
            .alert-button-group {
                box-shadow: unset;
                .c_ion_alert--support_button {
                    min-width: 12rem;
                    padding-top: .5rem;
                    padding-left: .5rem;
                    display: inline-flex;
                    align-items: start;

                    span {
                        display: inline-flex;
                        width: 10rem;
                    }

                    &:before {
                        content: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'><path fill='none' d='M0 0h24v24H0z'/><path d='M20.01 15.38c-1.23 0-2.42-.2-3.53-.56-.35-.12-.74-.03-1.01.24l-1.57 1.97c-2.83-1.35-5.48-3.9-6.89-6.83l1.95-1.66c.27-.28.35-.67.24-1.02-.37-1.11-.56-2.3-.56-3.53 0-.54-.45-.99-.99-.99H4.19C3.65 3 3 3.24 3 3.99 3 13.28 10.73 21 20.01 21c.71 0 .99-.63.99-1.18v-3.45c0-.54-.45-.99-.99-.99z'/></svg>");
                        filter: contrast(0) brightness(100);
                    }
                }
            }
        }
    }
}

.c_ion_alert--title_large {
    .alert-head .alert-title {
        line-height: 1.2;
    }
}

