@import "../../../../../../libs/pattern-library/utility-mixins";

.patient_surveys--wrapper {
    font-size: 1em;
    background-color: var(--gray-1);

    .patient_surveys--day_wrapper {
        border-radius: 10px;
        box-shadow: var(--shadow-md);
        min-height: 200px;
        font-size: 18px;

        .patient_surveys--spinner {
            text-align: center;
            width: 100%;
            margin-top: 20px;
        }

        .patient_surveys--empty_survey {
            text-align: center;
            font-size: 20px;
            padding: 30px 30px 50px 30px;
        }

        .patient_surveys--header_block {
            display: flex;
            background-color: var(--color-primary);
            color: var(--white);
            padding: 10px;
            font-weight: bold;
            letter-spacing: .04rem;
            margin-bottom: 1rem;
            border-radius: 10px 10px 0 0;

            .header_block--left,
            .header_block--right {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                width: 10%;
            }

            .header_block--right {
                align-items: flex-end;
            }

            .secondary_date {
                font-size: 13px;
                line-height: 17px;
                font-weight: var(--font-weight-regular);
            }

            .header_block--center {
                display: flex;
                flex: 1;
                flex-direction: column;
                align-items: center;

                .primary_label {
                    font-size: 13px;
                }
            }

        }

        .patient_surveys--grouped_block {
            margin: 10px;
            background-color: var(--white);
            border-radius: 5px;
            padding: 1px;
        }

        .patient_surveys--ungrouped_block {
            margin: 7px;
            background-color: var(--white);
            border-radius: 5px;
            padding: 1px;
        }

        .block_header {
            background-color: var(--gray-7);
            color: var(--white);
            padding: 6px 12px 6px 12px;
            border-radius: 5px 5px 0 0;
            min-height: 60px;

            .header_block--label {
                font-weight: var(--font-weight-heavy);
            }

            .header_block--score_label {
                font-weight: var(--font-weight-black);
            }
        }

        .block_body {
            margin: 9px 7px;
            border-radius: 7px;
            font-weight: var(--font-weight-regular);
            border: 1px solid black;

            .block_body--question {
                border-bottom: 1px solid black;
                padding: 7px 15px 13px 15px;
            }

            .block_body--answer {
                padding: 7px 15px 13px 15px;
            }

            span {
                font-weight: var(--font-weight-black);
            }
        }

        .c_buttons {
            background: inherit;

            .patient_surveys--reset_btn {
                @include u_ion-override-host-background-color;
                --override-background-color: var(--color-primary);
                width: fit-content;
                align-self: center;
                font-weight: bold;
                font-size: .9em;
                text-transform: capitalize;
                height: 3rem;
                margin: 1em auto;
                box-shadow: var(--shadow-md);
                white-space: break-spaces;
            }
        }
    }
}
