.c_radio, %c_radio {
    -webkit-appearance: none;
    height: 24px;
    width: 24px;
    border: 3px solid var(--color-border, black);
    border-radius: 24px;
    cursor: pointer;

    &:focus {
        outline: none;
        border-color: var(--color-primary);
    }

    &:checked,
    &.checkbox-checked {
        border-color: var(--color-primary);
        background-image: radial-gradient(at 50% 50%, var(--color-primary) 50%, transparent 52%);
    }
}
