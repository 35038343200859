// ion-toast (https://ionicframework.com/docs/api/toast)
// ion toast is used in many places throughout CCM, PCM, and CGM and is used to notify
// users that something saved, failed, occurred, etc.
// The toast is NOT actionable.
// If a choice, or confirmation is necessary, instead use "ion-alert".
// This file is an override file to change the default ion-toast styles to more closely match our XD visual specs
// These classes should NOT be used on their own, as they come standard with <ion-toast>
// only the SUCCESS and FAILURE states are styled. The generic state is the default Ionic theme
.toast-success,
.toast-warning,
.toast-fail,
.toast-risk_low,
.toast-risk_med,
.toast-risk_high {
    --color: #fff;
    --border-radius: 10px;
    --box-shadow: var(--shadow-sm);
    // vvvvvvvvvv fallbacks for ios 13.3 and lower and browsers that don't support ::part()
    // in browsers where part() is not supported, "inheritance" will leak into the shadow DOM and apply these styles
    // UNLESS already specifically applied by ionic on the elements in the shadow DOM
    font-size: 1.125rem;
    font-weight: 500; // normal font-weight
    text-align: center;
    line-height: 1.5;
    // ^^^^^^^^^^ fallbacks for ios 13.3 and lower and browsers that don't support ::part()
    &::part(container) {
        margin-bottom: 1rem;
    }
    &::part(header) {
        font-size: 1.5rem;
        font-weight: 700;
        line-height: 1.208333333333333;
        margin-bottom: 1rem;
    }
    &::part(message) {
        font-size: 1rem;
        font-weight: 700;
        line-height: 1.125;
    }
}
.toast-success {
    --background: var(--color-success);
}
.toast-warning {
    --background: var(--color-warning);
}
.toast-fail {
    --background: var(--color-error);
}

.toast-risk {
    &_low { --background: var(--color-risk-low); }
    &_med { --background: var(--color-risk-medium); }
    &_high { --background: var(--color-risk-high); }
}
