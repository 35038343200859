page-patient-status-change {
    .patient-discharge-info {
        white-space: normal;
    }

    .c_patient_status {

        &--buttons {
            display: flex;

            ion-button {
                flex: 1;
            }
        }

        &--deactivate {
            ion-label {
                white-space: break-spaces !important;
            }
        }
    }

    ion-item {
        --border-color: transparent;
    }
}
