
.c_app_menu--header {
    display: flex;
    height: 4em;
    position: sticky;
    align-items: center;
    width: 100%;
    z-index: 81;
    background-color: var(--white);
    box-shadow: var(--shadow-header);

    .c_app_menu--title {
        flex-basis: 63%;
        margin-left: auto;
        display: flex;
        place-content: center;
        position: relative;
        top: 0.4em;
        font-size: 1.1em;
        font-weight: 600;
    }

    .c_app_menu--btn-close {
        position: relative;
        flex-basis: 18%;
        text-align: left;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-size: 1.8em;
        right: 0.3em;
        height: 88%;
        top: 0.3em;

        .c_app_menu--btn-close-icon {
            content: url(/../assets/icon/baseline-close-24px.svg);
        }
    }
}

.c_app_menu--list {
    & > .item-block {
        margin-top: 9px;
        & .item-inner,
        &:first-child,
        &:last-child {
            border: none;
        }

        &:last-child::before {
            border-bottom: none;
        }
    }
}

.c_app_menu--item {
    --border-color: transparent;
    --detail-icon-color: transparent;
    &::after {
        --color-menu-item-border: rgba(0, 0, 0, 0.16);
        content: '';
        position: absolute;
        bottom: 0;
        left: 5%;
        width: 90%;
        border-bottom: 1px solid var(--color-menu-item-border);
    }

    &.item-md::before {
        left: 0;
        width: 100%;
    }

    .c_app_menu--icon {
        vertical-align: text-bottom;
        margin-right: .4em;
        &-add_patient {content: url(/assets/icon/baseline-person_add-24px.svg); }
        &-support {content: url(/assets/icon/baseline-phone_in_talk-24px.svg); }
        &-logout {content: url(/assets/icon/baseline-exit_to_app-24px.svg); }
    }
}

.app-version {
    position: absolute;
    bottom: 1em;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}

