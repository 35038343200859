@import "../../theme/variables";

#patient-list--content.top-border { border-top: 1px solid var(--gray-2); }

.c_patient_list_item {
    display: flex;
    flex-direction: column;
    flex: auto;
    min-height: 150px;
    padding: 1.25em 1em 1em 1em;
    margin: 0;
    border-radius: 0;

    &.even {
        background-color: var(--gray-1);
        .c_patient_list--accordion {
            &_list {
                --background: var(--gray-1);
            }
        }
    }

    .c_patient_list_item--btn-reviewed {
        --padding-start: 0;
        --padding-end: .5rem;
        max-width: none;

        &-in_progress { color: var(--color-risk-medium); }
        &-reviewed { color: var(--color-risk-low); }
        &-not_reviewed { color: var(--color-risk-high); }

        ion-icon {
            font-size: 25px;
        }

        ion-spinner {
            width: 20px;
            height: 20px;
        }

        .icon-ios {
            font-weight: bold;
        }
    }
    .c_patient_list_item--details {
        display: flex;
        align-items: center;
        padding: unset;

        ion-button:first-of-type {
            flex-basis: 10%;
        }

        ion-label:nth-of-type(1) {
            flex-basis: 60%;
            margin-top: 0;
            margin-bottom: 0;
        }

        ion-label:nth-of-type(2) {
            flex-basis: 10%;
        }

        ion-label:nth-of-type(3) {
            flex-basis: 20%;
        }

        .patient-detail-label {
            margin-bottom: 8.5%;
            text-align: right;
        }

        .c_patient_list_item--icon-comms {
            --padding-start: 0;
            --padding-end: 0;
            background: var(--color-primary);
            border: 1px solid var(--color-primary);
            border-radius: 3px;
            color: var(--white);
            width: 2em;
            height: 1.7em;

            .button-inner {
                padding: 3px;
            }
        }

        .c_patient_list_item--btn-open {
            --padding-start: 0;
            --padding-end: 0;
            width: 2em;
            height: 1.7em;
            margin-right: 12px;

            .c_patient_list_item--icon-arrow {
                height: 1.7em;
                width: 1.4em;
            }
        }
    }
    .c_patient_list--accordion {
        padding-right: 15%;
        height: 300px;

        &_list {
            --border-color: transparent;
            --padding-start: 0;
        }
        .c_patient--accordion-conditions {
            background-color: var(--color-accent);

            .c_patient_list--conditions_label {
                white-space: inherit;
                text-align: left;
            }
        }
    }
}

.patient-detail {
    font-weight: 500;
    text-align: left;
}

.patient-detail-status {
    font-weight: 500;
    text-transform: capitalize;
}

.metricTitle {
    text-transform: capitalize;
}

button.item {
    padding-left: 15px;

    .item-inner {
        //Was getting messy trying to override preset ionic styles so needed !important
        border-bottom: none !important;
        padding-left: 5px;
    }
}

.buttonTwo {
    margin: 0;
    max-width: 30px;

    .button-inner {
        font-size: 1.7em;
    }

    ion-spinner {
        max-width: 20px;
        height: 20px;
    }

    .icon-ios {
        font-weight: bold;
    }
}

.reviewed {
    color: var(--color-success);
}

.notReviewed {
    color: var(--color-error);
}

.highrisk {
    background-color: var(--color-error);
}

.medrisk {
    background-color: var(--color-risk-medium);
}

.lowrisk {
    background-color: var(--color-success);
}

.search-container {
    height: 5rem;
    display: flex;
    align-items: center;
    background-color: var(--gray-1);
    .searchbar-input {
        background-color: var(--white);
    }

    .spinner-md {
        position: absolute;
        right: 18px;
    }
    .search-spinner {
        background-color: transparent;
        width: 3rem;
        height: 3rem;

        svg {
            height: 20px;
            width: 20px;
            margin-top: 5px;
        }
    }

    .search-spinner {
        margin-right: 1em;
    }
}

.search-button {
    font-size: 1.6rem;
    margin-right: 5px;
}

.searchbar-clear-icon {
    display: none !important;
}

@media only screen
and (min-device-width : 375px)
and (max-device-width : 812px)
and (-webkit-device-pixel-ratio : 3) {
    #patient-list--content {
        padding-top: .9em;
    }
}

@media only screen
and (min-device-width : 375px)
and (max-device-width : 812px)
and (-webkit-device-pixel-ratio : 3)
and (orientation : landscape) {
    #patient-list--title {
        top: .5em;
    }
}

@media only screen
and (min-device-width : 375px)
and (max-device-width : 812px)
and (-webkit-device-pixel-ratio : 3)
and (orientation : portrait) {
    #patient-list--title {
        top: -.25em;
    }
}

