ion-header ion-toolbar:first-child {
    padding-top: 2vh;
}

ion-toolbar,
.c_app_header {
    --padding-top: 1em;
    --ion-toolbar-color: var(--black);
    --min-height: 8.5vh;
    padding: 0;
    display: flex;
    align-items: center;
    box-shadow: var(--shadow-sm);
    .c_app_header--text-no-back {
        padding-left: .75rem;
    }
    .c_app_header--buttons {
        min-width: 2.55em;
        margin-right: 1em;

        .c_app_header--btn {
            --color: var(--black);
            position: relative;
            left: 0.2em;

            /* ICON path is located in app/global.scss for each app */
        }
    }

    .c_app_header--title {
        display: inherit;
        position: relative;
        text-align: left;

        .c_app_header--text {
            text-overflow: inherit;
            white-space: pre-wrap;
            font-size: 1.2em;
        }
    }

    &.is-modal { --background: var(--color-header-modal); }
}

@media screen and (min-width: 500px) {
    ion-header ion-toolbar:first-child {
        padding-top: 0;
    }
    ion-toolbar,
    .c_app_header {
        height: 4.5em;

        .c_app_header--title {
            .c_app_header--text {
                font-size: 1.65em;
            }
        }
    }
}
