@mixin hrs-custom-properties {
    /* ######################################################################################### %
                                                                           BREAKPOINTS
   % ######################################################################################### */
    --breakpoint-large: 1140px;
    --breakpoint-medium: 900px;
    --breakpoint-small: 768px;
    --breakpoint-xsmall: 544px;

    $hrs-max-width-mobile: 599px;
    $hrs-min-width-tablet: 600px;

    /* ######################################################################################### %
                                                                            BASE COLOR SET
    % ######################################################################################### */
    --color-primary: #0d76c6;
    --color-primary-light: #01a8fe;
    --color-primary-dark: #0f55a3;
    --color-accent: #38354a;
    --color-accent-light: #d7d2ed;
    --color-accent-dark: #171528;

    --white: #FFFFFF;
    --black: #000000;
    --gray-10: #001117;
    --gray-9: #14242b;
    --gray-8: #21353f;
    --gray-7: #2d4752;
    --gray-6: #375461;
    --gray-5: #536b76;
    --gray-4: #6f838c;
    --gray-3: #97a5ac;
    --gray-2: #c0c9cd;
    --gray-1: #e6e9eb;
    --gray-0: #f0f3f8;

    --color-success: #0A853B;
    --color-info: #1F6AA8;
    --color-warning: #eb7100;
    --color-error: #b92025;

    --color-risk-none: #5a7c79;
    --color-risk-low: #0ea84b;
    --color-risk-medium: var(--color-warning);
    --color-risk-high: #c62360;
    --color-notifications: #eb0004;

    /* ######################################################################################### %
                                                                    SEMANTIC COLOR VARIABLES
    % ######################################################################################### */
    --color-primary-bg: var(--white);
    --color-accent-bg: var(--gray-2);
    --color-secondary: var(--gray-8);

    --color-header-modal: var(--color-primary-light);

    --color-careplan-selected: #F39834;

    --color-patient-list-gray: #F5F7F7;

    --color-shadow: 19, 34, 41; /* rgba */

    /* ######################################################################################### %
                                                                        APPROVED BOX SHADOWS
    % ######################################################################################### */
    --shadow-xs-top: 0 -1px 2px 0 rgba(var(--color-shadow), .3);
    --shadow-sm: 0 3px 6px 0 rgba(var(--color-shadow), .3);
    --shadow-md: 0 3px 12px 0 rgba(var(--color-shadow), .6);
    --shadow-lg: 0 3px 18px 0 rgba(var(--color-shadow), .6);

    --shadow-header: 0 0 8px -5px rgba(var(--color-shadow), .6);
    --shadow-footer: -1px 0 12px -3px rgba(var(--color-shadow), .6);

    /* ######################################################################################### %
                                                                            Global font rules
    % ######################################################################################### */
    --font-family: "Lato", sans-serif !important;

    --font-weight-regular: 400;
    --font-weight-medium: 500;
    --font-weight-semibold: 600;
    --font-weight-bold: 700;
    --font-weight-heavy: 800;
    --font-weight-black: 900;

    --font-path: "../assets/fonts";

    /* ######################################################################################### %
                                    Named Color Variables  *** OLD VALUES -- NEED UPDATES
    % ######################################################################################### */
    --bright-blue-darken : #018dd5;  /* equal to: darken(#01a8fe, 8%); */
    --light-grey: #dedede;


    /* ######################################################################################### %
                                                                    STENCIL GLOBALS
    % ######################################################################################### */
    --color-outline: var(--color-primary-light);
    --shadow-inset-input: inset 0 1px 2px rgba(0,0,0,.39), 0 -1px 1px var(--white), 0 1px 0 var(--white);
    --shadow-focus-outline: 0 0 0px 4px var(--color-outline);
    --shadow-focus-invalid: 0 0 1px 2px var(--color-error);
}

/* ######################################################################################### %
                            Ionic Variables and Theming. For more info, please see:
                                                http://ionicframework.com/docs/v2/theming/
% ######################################################################################### */

/* $font-path: "../assets/fonts"; changed this to a custom property defined in the global @mixin above */

/* ######################################################################################### %
    App Theme
    --------------------------------------------------
    Ionic apps can have different themes applied, which can
    then be future customized. This import comes last
    so that the above variables are used and Ionic's
    default are overridden.

    @import "ionic.theme.default";

    Ionicons
    --------------------------------------------------
    The premium icon font for Ionic. For more info, please see:
    http://ionicframework.com/docs/v2/ionicons/

    @import "ionic.ionicons";
% ######################################################################################### */


/* ######################################################################################### %
                                                                        Fonts
% ######################################################################################### */
/*
// no longer using roboto or noto-sans.
// $font is has been replaced by --font-family custom property.
// $ion-font-roboto: "roboto";
// $ion-font-noto-sans: "noto-sans";
// $font: "Lato", sans-serif;
*/
/* ######################################################################################### %

    //// Ionic Variables and Theming. For more info, please see:
    //// http://ionicframework.com/docs/theming/
    //
    //Ionic Variables
    //:root {
    //  primary
    //  --ion-color-primary: #3880ff;
    //  --ion-color-primary-rgb: 56, 128, 255;
    //  --ion-color-primary-contrast: #ffffff;
    //  --ion-color-primary-contrast-rgb: 255, 255, 255;
    //  --ion-color-primary-shade: #3171e0;
    //  --ion-color-primary-tint: #4c8dff;
    //
    //  secondary
    //  --ion-color-secondary: #0cd1e8;
    //  --ion-color-secondary-rgb: 12, 209, 232;
    //  --ion-color-secondary-contrast: #ffffff;
    //  --ion-color-secondary-contrast-rgb: 255, 255, 255;
    //  --ion-color-secondary-shade: #0bb8cc;
    //  --ion-color-secondary-tint: #24d6ea;
    //
    //  tertiary
    //  --ion-color-tertiary: #7044ff;
    //  --ion-color-tertiary-rgb: 112, 68, 255;
    //  --ion-color-tertiary-contrast: #ffffff;
    //  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    //  --ion-color-tertiary-shade: #633ce0;
    //  --ion-color-tertiary-tint: #7e57ff;
    //
    //  success
    //  --ion-color-success: #10dc60;
    //  --ion-color-success-rgb: 16, 220, 96;
    //  --ion-color-success-contrast: #ffffff;
    //  --ion-color-success-contrast-rgb: 255, 255, 255;
    //  --ion-color-success-shade: #0ec254;
    //  --ion-color-success-tint: #28e070;
    //
    //  warning
    //  --ion-color-warning: #ffce00;
    //  --ion-color-warning-rgb: 255, 206, 0;
    //  --ion-color-warning-contrast: #ffffff;
    //  --ion-color-warning-contrast-rgb: 255, 255, 255;
    //  --ion-color-warning-shade: #e0b500;
    //  --ion-color-warning-tint: #ffd31a;
    //
    //  danger
    //  --ion-color-danger: #f04141;
    //  --ion-color-danger-rgb: 245, 61, 61;
    //  --ion-color-danger-contrast: #ffffff;
    //  --ion-color-danger-contrast-rgb: 255, 255, 255;
    //  --ion-color-danger-shade: #d33939;
    //  --ion-color-danger-tint: #f25454;
    //
    //  dark
    //  --ion-color-dark: #222428;
    //  --ion-color-dark-rgb: 34, 34, 34;
    //  --ion-color-dark-contrast: #ffffff;
    //  --ion-color-dark-contrast-rgb: 255, 255, 255;
    //  --ion-color-dark-shade: #1e2023;
    //  --ion-color-dark-tint: #383a3e;
    //
    //  medium
    //  --ion-color-medium: #989aa2;
    //  --ion-color-medium-rgb: 152, 154, 162;
    //  --ion-color-medium-contrast: #ffffff;
    //  --ion-color-medium-contrast-rgb: 255, 255, 255;
    //  --ion-color-medium-shade: #86888f;
    //  --ion-color-medium-tint: #a2a4ab;
    //
    //  light
    //  --ion-color-light: #f4f5f8;
    //  --ion-color-light-rgb: 244, 244, 244;
    //  --ion-color-light-contrast: #000000;
    //  --ion-color-light-contrast-rgb: 0, 0, 0;
    //  --ion-color-light-shade: #d7d8da;
    //  --ion-color-light-tint: #f5f6f9;
    //}
% ######################################################################################### **/


