
.c_checkbox, %c_checkbox {
    -webkit-appearance: none;
    height: 27px;
    width: 27px;
    background-color: transparent;
    border-radius: 8px;
    transform: rotate(-90deg);
    border: 3px solid var(--color-border, var(--black));
    transition: all 0.3s;
    cursor: pointer;

    &:focus {
        outline: 0;
        border: 3px solid var(--color-primary);
    }

    &:not(:checked) {
        .checkbox-icon {
            background-color: transparent;
        }
    }

    &:checked {
        border-radius: 4px;
        transform: rotate(0deg);
        opacity: 1;
        background-color: var(--color-primary);
        border-color: var(--color-primary);

        &:after {
            content: '';
            display: block;
            width: 11px;
            height: 20px;
            border: solid var(--white);
            border-width: 0 4px 4px 0;
            transform: rotate(45deg) translate(3px, -4px);
        }
    }
}
