/* ######################################################################################### %
Override Ionic :host Background Color 
% ######################################################################################### */
@mixin u_ion-override-host-background-color {
    /* @include this mixin to an ion element to override :host shadow-DOM colors:
        .example-class {
            @include u_ion-override-host-background-color;
            --override-background-color: [ASSIGN DESIRED COLOR VALUE];
        }
    */
    --override-background-color: transparent;
    --background-activated: var(--override-background-color);
    --background-focused: var(--override-background-color);
    --background-hover: var(--override-background-color);
    --background: var(--override-background-color);
    background: var(--override-background-color);
}
/* ######################################################################################### %
Override Ionic :host opacity (Ionic 5+ only - wait to use until after the ionic 5 migration is complete)
% ######################################################################################### */
@mixin u_ion-override-host-background-opacity {
    /* @include this mixin to an ion element to override :host shadow-DOM colors:
        .example-class {
            @include u_ion-override-host-background-color;
            --override-background-color: [ASSIGN DESIRED COLOR VALUE];
        }
    */
    --override-background-opacity: 1.0;
    --background-activated-opacity: 	//Opacity of the button when pressed
    --background-focused-opacity:	//Opacity of the button when focused with the tab key
    --background-hover-opacity:	//Opacity of the background on hover 
}
/* ######################################################################################### %
Override Ionic :host Text color
% ######################################################################################### */
@mixin u_ion-override-host-text-color {
    /* @include this mixin to an ion element to override :host shadow-DOM colors:
        .example-class {
            @include u_ion-override-host-text-color;
            --override-text-color: [ASSIGN DESIRED COLOR VALUE];
        }
    */
    --override-text-color: transparent;
    --color-activated: var(--override-text-color); // Text color of the button when pressed
    --color-focused: var(--override-text-color); // Text color of the button when focused with the tab key
    --color-hover: var(--override-text-color); // Text color of the button when hovered
    --color: var(--override-text-color); // Text color of the button
    color: var(--override-text-color);
}