/* ######################################################################################### %
                                                                        CUSTOM PROPERTIES
% ######################################################################################### */
@import 'custom-properties';
@import 'utility-mixins';
/* ######################################################################################### %
                                                                        COMPONENT PATTERNS
% ######################################################################################### */
@import 'c_app_header';
@import 'c_button';
@import 'c_call';
@import 'c_card';
@import 'c_chat';
@import 'c_checkbox';
@import 'c_footer';
@import 'c_form';
@import 'c_radio';
@import 'c_splash';
@import 'c_ion_alert--override';
@import 'c_ion_toast--override';
/* ######################################################################################### %
                                                                        GLOBAL RULES
% ######################################################################################### */
ion-refresher {
    background: transparent;
    z-index: 1;
    opacity: 1;
    &.refresher-completing {
        opacity: 0;
        transition: opacity 200ms ease;
    }
}

ion-content { background: var(--white); }

:host {
    --ion-font-family: var(--font-family);
    --ion-default-font: var(--font-family);
}

html.ios,
html.md,
html {
    --ion-default-font: "Lato", sans-serif;
    color: var(--color-accent);
}

.ios ion-menu {
    margin-top: 20px; /* Status bar height on iOS 10 */
    margin-top: constant(safe-area-inset-top); /* Status bar height on iOS 11.0 */
    margin-top: env(safe-area-inset-top); /* Status bar height on iOS 11+ */
}
// accessibility - sr-only (screen reader-only) can be applied to any element (almost always a <span>) to hide text from sighted users and have it read only by screan readers
.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}
/* ######################################################################################### %
                                                                        GLOBAL FONT
% ######################################################################################### */
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
