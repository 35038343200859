
.is-scrolling {
    display: none;
}

.c_chat--list {
    padding-top: 1em;
    margin-bottom: 0;

    .c_chat {
        display: grid;
        grid-template-columns: 100%;
        padding: 0;

        .c_chat--timestamp-right,
        .c_chat--timestamp-left {
            position: relative;
            font-size: 11px;
            margin: 0.1vh 0 3.5vh 0;
            width: 94%;
        }
        .c_chat--timestamp-right {
            text-align: right;
            margin-left: 0;
        }
        .c_chat--timestamp-left {
            text-align: left;
            margin-right: auto;
            left: 6%;
        }
        .c_chat--message {
            display: flex;
            min-height: 40px;
            text-align: left;
            .c_chat--bubble-right,
            .c_chat--bubble-left {
                position: relative;
                padding: 5px 10px 10px;
                width: 50%;
                .c_chat--msg_name {
                    font-size: 14px;
                    font-weight: bold;
                }
                .c_chat--msg_text {
                    white-space: pre-wrap;
                    font-size: 12px;
                    margin-top: 2px;
                }
                .c_chat--msg_name,
                .c_chat--msg_text {
                    display: block;
                    text-align: left;
                }
            }
            .c_chat--bubble-right:after,
            .c_chat--bubble-left:after {
                content: '';
                position: absolute;
                top: 0;
                width: 0;
                height: 0;
                border-style: solid;
            }

            .c_chat--bubble-right {
                left: 45%   ;
                background: var(--bright-blue-darken);
                border-radius: .4em 0 .4em .4em;
                color: var(--white);
                &:after {
                    right: 0;
                    border-color: var(--bright-blue-darken) var(--bright-blue-darken) transparent var(--bright-blue-darken);
                    margin-top: 0;
                    margin-right: -17px;
                    border-width: 0 0 16px 17px;
                }
            }

            .c_chat--bubble-left {
                background: var(--light-grey);
                left: 5%;
                border-radius: 0 .4em .4em .4em;
                color: var(--color-accent-dark);
                &:after {
                    left: 0;
                    border-width: 16px 0 0 17px;
                    border-color: var(--light-grey) var(--light-grey) transparent transparent;
                    margin-top: 0;
                    margin-left: -17px;
                }
            }
        }
    }
}

.c_chat--footer {
    background: var(--white);
    min-height: 9em;
    box-shadow: -1px 0 8px -5px var(--black);

    .c_chat--form {
        display: grid;
        grid-template-columns: 63% 35%;
        grid-template-rows: 7em;
        align-items: center;
        justify-content: center;

        .c_chat--input,
        hrs-textarea {
            --padding-end: .5em;
            --padding-start: .5em;
            text-align: left;
            height: 88%;
            width: 92%;
            margin: 1em 0 0 .5em;
            box-shadow: inset 0px 0px 6px -2px;
            border-radius: 8px;

            textarea {
                height: 100%
            }
        }

        hrs-textarea.c_chat--input {
            --textarea-border: 1px solid transparent;
        }

        .c_chat--btn-send {
            @include u_ion-override-host-background-color;
            --padding-start: 0;
            --padding-end: 0;
            --border-radius: 5px;
            --box-shadow: var(--shadow-sm);
            background-color: transparent; // override the background-color css property set in the above mixin to make sure the parent element doesn't get the dark blue background or else the rounded corners will dissapear
            --override-background-color: var(--color-primary);
            height: 2.5em;
            text-transform: uppercase;
            justify-self: center;
            width: min-content;
            min-width: 80%;
            margin-right: 5px;

            .c_chat--icon-send {
                margin-right: .4em;
            }
        }

        .c_chat--btn-content {
            padding: 5px;
        }
    }
}
