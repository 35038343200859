
.c_call {
    --background: var(--color-accent-light);
    .scroll-content {
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .c_call--status,
    .c_call--name-callee {
        font-size: var(--call-font-size, #{1rem});
        text-align: center;
        color: var(--color-accent);
        width: 100%;
        font-weight: bold;
        z-index: 1000;
    }

    .c_call--name-callee {
        --call-font-size: #{2rem};
        margin-top: 2.5vh;
    }
}

