/* ######################################################################################### %
                                                                BUTTON TEMPLATE:
    <ion-buttons class="c_buttons">
        <--! SINGLE LEFT BUTTON -->
        <button ion-button class="c_button" (click)="next()">{{ 'NEXT_BUTTON' | translate }}</button>

        <--! LEFT AND RIGHT BUTTONS  -->
        <button ion-button class="c_button-left" (click)="previous()">{{ 'PREVIOUS_BUTTON' | translate }}</button>
        <button ion-button class="c_button" (click)="next()">{{ 'NEXT_BUTTON' | translate }}</button>

        <--! SINGLE CENTERED BUTTON -->
        <button class="c_button-center" ion-button type="button" (click)="dismiss()">
            <span ion-text>{{ 'BACK_BUTTON' | translate }}</span>
        </button>
    </ion-buttons>
% ######################################################################################### */

@import "utility-mixins";

@mixin c_buttons {
    display: flex;
    padding: 0 1em;
}

@mixin c_button {
    --button-width: 7em;
    --button-color: var(--color-primary);
    @include u_ion-override-host-background-color;
    --override-background-color: var(--button-color);
    background-color: var(--color-primary);
    --border-radius: 3px;
    height: 2.2em;
    width: var(--button-width);
    align-self: center;
    margin-left: auto;
    border-radius: 5px;
    font-size: 1.3em;
    text-transform: uppercase;
    opacity: 1;
    color: var(--white);
    box-shadow: 0 6px 3px -4.4px rgba(0, 0, 0, .34);
}

@mixin c_button-left {
    @include c_button;
    --button-color: --color-primary;
    margin-left: .1em;
}

@mixin c_button-center {
    @include c_button;
    --button-color: --color-primary;
    --button-width: 15.5em;
    width: var(--button-width);
    margin: 0 auto;
}

@mixin c_button-wide {
    @include c_button-center;
    --button-color: --color-primary;
    width: var(--button-width);
    align-self: center;
    margin-top: 1em;
}
.c_buttons { @include c_buttons; }
.c_button { @include c_button; }
.c_button-center { @include c_button-center; }
.c_button-left { @include c_button-left; }
.c_button-wide { @include c_button-wide; }

