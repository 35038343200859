
.resetMetricsModal {
    .resetMetricsButtons {
        padding: 1em;
    }

    .list-ios > .item-block:first-child {
        border-top: 0;
    }

    .list-ios > .item-block:last-child {
        border-bottom: 0;
    }
}
