@import "c_button";
/* ######################################################################################### %
                                                                CARD TEMPLATE:
    <ion-card class="c_card">
        <ion-card-header class="c_card--header">
            <ion-label>TITLE OF CARD</ion-label>
        </ion-card-header>
        <ion-card-content class="c_card--content">
            CONTENT GOES HERE
        </ion-card-content>
        <ion-buttons class="c_card--buttons">
        <--! SINGLE LEFT BUTTON -->
        <button ion-button class="c_card--button" (click)="next()">{{ 'NEXT_BUTTON' | translate }}</button>
        <--! LEFT AND RIGHT BUTTONS -->
        <button ion-button class="c_card--button-left" (click)="previous()">{{ 'PREVIOUS_BUTTON' | translate }}</button>
        <button ion-button class="c_card--button" (click)="next()">{{ 'NEXT_BUTTON' | translate }}</button>
        <--! SINGLE CENTERED BUTTON -->
            <button class="c_card--button-center" ion-button type="button" (click)="dismiss()">
                <span ion-text>{{ 'BACK_BUTTON' | translate }}</span>
            </button>
        </ion-buttons>
    </ion-card>
% ######################################################################################### */
@mixin doubleButtonWidth() {
    // sets the widths of buttons when 2 buttons sit on the same row. Accounts for different device widths and varying button text size (due to different languages)
    width: auto;
    min-width: 7em;
    max-width: 50%;
    max-width: calc(50% - 1px);
}

.c_card, %c_card {
    --card-bg: var(--white);
    display: flex;
    flex-direction: column;
    height: fit-content;
    padding: 0;
    border-radius: .9em;
    background-color: var(--card-bg);
    box-shadow: var(--shadow-sm);

    .c_card--header, %c_card--header {
        --card_header-bg: var(--color-accent);
        --card_header-bradius: 0.9em 0.9em 0 0;
        display: flex;
        justify-content: var(--card_header-justify, center);
        align-items: center;
        height: var(--card_header-height, 3.5em);
        padding: 0; // padding top removed to handle c_card--header's with 2 lines
        border-radius: var(--card_header-bradius);
        text-align: center;
        font-size: 1.2em;
        font-weight: bold;
        color: var(--white);
        background-color: var(--card_header-bg);
    }

    .c_card--content, %c_card--content, .c_quiz_card--content {
        height: fit-content;
        padding: 0;
        margin: .5em 0 .5em 0;
        display: flex;
        flex-direction: column;
        font-weight: bold;
        font-size: 1.1em;

        .c_card--title {
            width: 100%;
            padding: 1em;
            color: var(--black);
            font-weight: bold;
            font-size: 1rem;
            line-height: 1.3125;
        }

        .c_card--list_wrapper {
            height: auto;
            min-height: 60%;
            display: flex;

            .c_card--list, %c_card--list {
                --list-padding-left: 0;
                width: 100%;
                height: auto;
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;

                .c_card--list-item {
                    --border-color: transparent;
                    --padding-end: 1em;
                    background-color: var(--white);
                }

                .c_card--checkbox {
                    .checkbox-icon {
                        border: .1em solid var(--gray-6);

                        &:not(.checkbox-checked) {
                            background-color: var(--white);
                        }

                        &.checkbox-checked {
                            border-color: var(--color-primary-light);
                        }
                    }

                    &-label {
                        white-space: normal;
                    }
                }
            }
        }
    }
    .c_card--content-padded {
        padding: 1rem;
    }
    .c_card--text {
        margin-bottom: 1rem;
        font-size: 1.125rem;
        font-weight: normal;
        color: var(--color-accent);
        line-height: 1.22222223;
    }
    .c_card--buttons {
        height: fit-content;
        display: flex;
        justify-content: space-between;
        margin: auto 0 0 0;
        padding: .5em;
        box-shadow: 0 3px 11px -1px rgba(52, 52, 52, 0.8);
        .c_card--button {
            @include c_button;
            @include doubleButtonWidth();
            &-left { 
                @include c_button-left;
                margin-left: 0;
                @include doubleButtonWidth();
             }
            &-center { @include c_button-center; }
            &[disabled] {
                opacity: .5;
                cursor: not-allowed;
            }
        }
    }

    &.is-completed {
        height: fit-content;
        min-height: 40vh;
        background-color: var(--white);

        .c_card--content {
            height: fit-content;
            padding: 1em;
        }

        .c_card--buttons {
            box-shadow: none;
            margin: auto 0 3em 0;
        }
    }
}

ion-checkbox {
    --border-radius: 5px;
    &.is-radio {
        --border-radius: 24px;
    }
}


@media (orientation: landscape) and (min-width: 500px) {
    .l_modal-multistep {
        .modal-wrapper {

            .c_card,
            .c_quiz_card,
            .c_quiz_card.is-completed {

                    .c_card--header {
                        font-size: 1.5em;
                        height: 2.65em;
                        padding: 0;
                    }

                    .c_card--content,
                    .c_quiz_card--content {
                        padding: 3.4em;

                        .c_card--title {
                            color: var(--black);
                            width: 100%;
                            padding: 0;
                            font-size: 2.5em;
                        }

                        .c_card--list-item {

                            .c_card--checkbox.is-checkbox {
                                border-radius: 0.4em;
                                height: 2em;
                                width: 2em;
                                border: 2px solid black;

                                &.checkbox-checked {
                                    border: transparent;
                                }
                            }

                            .c_card--item-label {
                                font-size: 2.2em;
                                line-height: 1.5;
                                font-weight: 400;
                                margin: 0 0.5em;

                                &.c_quiz_card--item-label {
                                    font-size: 1.6em;
                                }
                            }
                        }
                    }


                    .c_card--footer {
                        padding: 1.04em;

                        [class^="c_card--button"] {
                            height: 2.47em;

                            ion-text {
                                font-size: 1.2em;
                            }
                        }
                    }
                }
            }

    }
}

@media (orientation: portrait) and (min-width: 500px) {
    .l_modal-multistep {
        .modal-wrapper {

            .c_card,
            .c_quiz_card,
            .c_quiz_card.is-completed {

                .c_card--header {
                    font-size: 1.5em;
                    height: 3.35em;
                    padding: 0;
                }

                .c_card--content {
                    padding: 2.5em;
                }

                .c_card--title {
                    color: var(--black);
                    padding: 0;
                    font-size: 2.5em;
                }

                .c_card--list-item {

                    .c_card--checkbox.is-checkbox {
                        border-radius: 0.4em;
                        height: 2em;
                        width: 2em;
                        border: 2px solid black;

                        &.checkbox-checked {
                            border: transparent;
                        }
                    }

                    .c_card--item-label {
                        margin-left: .3em;
                        font-size: 1.6em;
                        line-height: 1.5;
                        font-weight: 400;
                    }
                }

                .c_card--footer {
                    padding: 1.04em;

                    [class^="c_card--button"] {
                        height: 2.3em;

                        ion-text {
                            font-size: 1.1em;
                        }
                    }
                }
            }
        }
    }
}
