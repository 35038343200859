@import "../../libs/pattern-library/c_card";
@import "utility-mixins";
/////////////////////////
// c_splash
// applies to CCM, CGM, PCM pages:
// CCM: welcome, login, organizational-login
// PCM: welcome, login
// CGM: welcome, login, signup, pin-entry
////////////////////////

.c_splash {
    &--page {
        --background: none;
        background: url(/../../assets/img/bg_1.png) no-repeat 0 0;
        background-size: cover;

        // default button properties for all "splash" screens (welcome, login, signup, etc)
        // button text is translated to languages other than english where the length of words is longer
        .c_button {
            --button-width: auto;
            width: var(--button-width); // ensure buttons are only as long as their text (unless we specify elsewhere) but also expand to fit any length of text.
            white-space: normal; // overried ionic's white-space: nowrap setting to ensure that multiple words (when translated to other languages and gets longer) will wrap to a second line when space is limited
            word-break: break-word; // if a single word is too large for the button's container, break the word to allow it to fit on 2 lines (some German words and other languages can have very long words)
        }
    }

    &--wrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        height: 100vh;
        padding: 0 1em;
    }

    &--logo_container {
        text-align: center;
        position: relative;
        justify-self: center;
        width: 100%;
        height: 28vh;
        margin-top: 1em;
        min-height: 10em;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: calc(15px + (19 - 15) * (100vh - 568px) / (896 - 568));
    }

    &--logo,
    &--logo_bg {
        position: absolute;
        margin: auto;
        height: var(--logo-height, 6em);
        width: var(--logo-width, 16em);
        border-radius: .6em;
    }

    &--logo {
        background: url(/../../assets/img/HRS_logo_vector.svg) no-repeat center;
        z-index: 1;
        background-size: 14.3em;
        shape-rendering: crispedges;
    }

    &--logo_bg {
        background-color: var(--gray-1);
        opacity: 0.55;
        backdrop-filter: blur(3em);
        z-index: 0;
    }

    &--page {

        .c_splash--card_container {
            --card-border-radius: .625rem;
            --card-height: 100%;
            --card-width: 100%;
            --splash-shadow: 0 3px 10px -4px var(--gray-8);

            display: flex;
            justify-content: center;
            height: fit-content;
            width: 100%;
            place-self: center;
            position: relative;
        }
        .c_splash--card_bg {
            background-color: var(--gray-1);
            position: absolute;
            opacity: .55;
            height: var(--card-height);
            width: var(--card-width);
            border-radius: var(--card-border-radius);
        }

        // the physical card (BOTH welcome and interior pages)
        .c_splash--card {
            border-radius: var(--card-border-radius);
            height: var(--card-height);
            margin: 0;
            min-height: fit-content;
            padding: 1.5em .5em;
            position: relative;
            width: var(--card-width);
            box-shadow: 0 15px 11px -15px var(--gray-8);
            -webkit-backdrop-filter: blur(20px);
            backdrop-filter: blur(20px);

            // welcome card - title "Welcome to <app-name>Connect"
            &_title {
                color: var(--color-accent);
                text-align: center;
                font-size: calc(26px + (35 - 28) * (100vh - 568px) / (896 - 568));
                text-transform: uppercase;
                font-weight: 600;
            }

            // wrapper around buttons on BOTH the welcome screens and the interior login, register, etc. screens
            .c_buttons {
                font-size: calc(14px + (19 - 15) * (100vh - 568px) / (896 - 568));
                margin: 1.4em 0;
                padding: 0;
                text-transform: uppercase;
                width: 100%;
            }
        }

        // welcome card - wrapper around the buttons in the middle of the screen
        .c_splash--buttons {
            display: flex;
            flex-direction: column;
            flex: 1 2em;
            .c_button + .c_button {
                margin-top: calc(10px + (19 - 15) * (100vh - 568px) / (896 - 568));
            }
            .c_button-center {
                position: relative;
            }
        }
        // welcome card - cgm only - make buttons a little wider and equal width
        &.c_splash--cgm {
            .c_button {
                min-width: 12rem;
            }
        }
        // welcome card - footer
        .c_splash--footer {
            position: relative;
        }
         // welcome card - copyright text in the footer
        .c_splash--copyright {
            color: var(--black);
            display: flex;
            text-align: center;
            flex-direction: column;

            &_text {
                font-size: calc(14px + (18 - 14) * (100vh - 568px) / (896 - 568));
                font-weight: 500;
                line-height: 1.2;
            }

            &_title,
            &_terms {
                font-size: calc(15px + (19 - 15) * (100vh - 568px) / (896 - 568));
                font-weight: 600;
            }

            a {
                color: var(--color-primary-dark);
                text-decoration: none;
            }
        }


        // "is-login" cards only (all 'interior' pages)
        // INCLUDES - PCM login (security setup/optout, pin-entry),
        //    CCM login, org-login, CGM login, register, pin-entry
        // EXCLUDES - welcome cards (ccm, pcm, cgm)
        .c_splash--card_container.is-login {
            .c_splash--card {
                padding: 0;
                display: flex;
                flex-direction: column;
            }
            .c_splash--header_bg,
            .c_splash--footer_bg {
                width: 100%;
                position: absolute;
                background: var(--white);
                height: 100%;
                z-index: -1;
                border-radius: var(--card-border-radius) var(--card-border-radius) 0 0;
                opacity: .55;
                margin: 0;
            }

            .c_splash--footer_bg {
                border-radius: 0 0 var(--card-border-radius) var(--card-border-radius);
            }

            .c_splash--card_header {
                position: relative;
                display: flex;
                align-items: center;
                min-height: fit-content;

            }
            .c_splash--back_btn {
                @include u_ion-override-host-background-color;
                --override-background-color: transparent;
                --padding-end: 0;
                background: url(/assets/icon/icon-back_btn.svg);
                background-repeat: no-repeat;
                background-color: var(--color-primary);
                background-position: center;
                background-size: 0.8em;
                border-radius: .19em;
                font-size: 2.1em;
                height: 1.3em;
                width: 1.3em;
                justify-self: flex-start;
                box-shadow: var(--splash-shadow);
                margin: .3em;
                a {
                    padding: 0;
                }
            }

            .c_splash--title_text {
                margin: auto;
                margin-left: 0;
                font-size: calc(24px + (30 - 24) * (100vh - 568px) / (896 - 568));
                flex-basis: 65%;
                text-align: center;
                text-transform: uppercase;
                color: var(--color-primary);
                font-weight: 800;
                display: flex;
                place-content: center;
                align-items: center;
            }
            .c_splash--card_content {
                position: relative;
                margin: 0;
                height: fit-content;
                box-shadow: inset 0 10px 17px -17px var(--gray-8),
                            inset 0 -10px 17px -17px var(--gray-8);
                padding: 0 1rem;
                ion-item, ion-list {
                    @include u_ion-override-host-background-color;
                    --override-background-color: transparent;
                    margin: .5em 0;
                }
            }

            .c_splash--card_footer {
                position: relative;
                height: fit-content;
                display: flex;
                justify-content: flex-end;
                margin-top: 0;
                min-height: fit-content;

                .c_button {
                    margin: .5em;
                }

                // button that apperas on the left side (usually the "BACK" button)
                .c_button-left {
                    margin-right: auto;
                }

                //disabled button state
                .c_button {
                    &[disabled] {
                        opacity: .5;
                    }
                }

            }

            &.is-setup,
            &.is-signup,
            &.is-pin {

                .c_splash--card {
                    &_header {
                        min-height: 4em;
                        .c_splash--title_text {
                            font-size: calc(17px + (21 - 18) * (100vh - 568px) / (896 - 568));
                            &.is-cg_pin {
                                margin: auto;
                                flex-basis: 100%;
                            }
                        }
                    }
                    &_content {
                        box-shadow: none;

                        ion-item.c_form--field-group {
                            --inner-border-width: 0;
                            display: flex;
                            align-items: center;
                            margin: 0;
                            width: 100%;

                            .c_form--label-required {
                                max-height: 20px;
                                &:before {
                                    margin-top: 3px;
                                }
                            }
                            ion-input {
                                margin-top: 0;
                                input, ::placeholder {
                                    margin-top: 0;
                                }
                            }
                        }
                    }
                    &_footer {
                        min-height: 4em;
                        margin-bottom: 0;
                    }
                }
            }

            &.is-setup,
            &.is-pin {
                .c_splash--card {
                    &_content {
                        box-shadow: none;
                        .c_splash--input_field,
                        .c_form--field {
                            text-transform: uppercase;
                        }
                    }
                    .c_buttons {
                        margin: 0;
                    }
                    .c_button-center {
                        margin: 0 auto;
                    }
                }
            }

            &.is-setup {
                text-align: center ;

                hrs-card {
                    background-color: var(--gray-1);
                }

                .c_splash--title_text {
                    margin-left: auto ;
                }

                .c_form--field-group {
                    min-height: unset;
                }

                .c_form--field-basic {
                    max-width: 5rem;
                    margin: 0 auto 0 auto;
                }
            }
        }

    }
    // interior pages - the form element and wrapper around the form fields
    &--form,
    &--input_wrapper {
        height: fit-content;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }

    &--input_wrapper {
        // @hrsui component adjustment to match existing ion elements in ion-list
        hrs-form-item.has-floating-label {
            --item-margin: .6rem 0;
            --item-width: 100%;
            hrs-input {
                button.input--clear {
                    margin-right: .5rem;
                }
            }
        }
    }

    &--text {
        margin-bottom: 1em;
    }

    &--input_wrapper {
        background: transparent;
    }

    &--input {
        --background: inherit;
        --border-color: var(--gray-5);
        --color: var(--gray-6);
        --padding-end: 1em;
        font-size: 1.2em;
        width: 100%;

        &_label {
            color: var(--color);
            font-style: italic;
            margin: 0;
            text-align: center;
        }
    }

    // PCM Contact Support section (Welcome card)
    &--contact {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: .5em;

        .c_splash--button-call {
            @include u_ion-override-host-background-color;
            --override-background-color: transparent;
            background: url(/../../assets/icon/icon-call.svg) center no-repeat var(--color-primary);
            background-size: 2em;
            border-radius: .3em;
            box-shadow: var(--shadow-sm);
            height: 3.5em;
            flex-shrink: 0;
            font-size: 1em;
            margin-right: 1em;
            width: 3.5em;
        }

        .c_splash--contact_text {
            color: var(--black);
            font-weight: 600;
            margin-top: 0 !important;

            p {
                font-size: calc(15px + (18 - 15) * (100vh - 568px) / (896 - 568));
                margin: 0 !important;
            }
        }
    }
}

// PCM Welcome & Sign In HRSUI update
.c_splash--pcm {

    .c_splash--welcome_container,
    .c_splash--login_container {
        position: relative;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        align-self: center;
    
        width: 100%;
        max-width: 36.5rem;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
    }

    .c_splash--welcome_container {
        justify-content: space-between;
    }

    .c_splash--welcome_intro {
        hrs-text {
            --text-color: var(--color-primary-light);
        }
    }

    .c_splash--app_name {
        margin-top: .25rem;
        margin-bottom: .5rem;
    }

    .c_splash--logo_container {
        position: relative;
        margin: 0;
        height: 7vh;
        min-height: 2.5rem;
        align-items: flex-start;
        justify-content: flex-start;
    }
    
    .c_splash--logo {
        position: absolute;
        margin: 0;
        height: 7vh;
        min-height: 2.5rem;
        width: initial;
        background: none;
    }

    .c_splash--signin_image_container {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 1rem;
    }

    .c_splash--signin_image {
        margin: 0;
        height: 40vh;
        min-height: 12.75rem;
        width: initial;
        background: none;
    }

    .c_splash--copyright_title {
        margin-bottom: .25rem;
    }

    .c_splash--form {
        flex-shrink: 0;
        justify-content: space-between;
    }

    .c_splash--form_inputs {
        width: 100%;
        margin-top: 1.5rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .c_splash--contact {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: .75rem;
    }

    hrs-form-item {
        --item-margin: 0;
        --item-padding: 0;
        --item-height: initial;
        
        margin-top: 1.5rem;
    }

    hrs-button {
        --width: 100%;
        margin-top: 1.5rem;
    }

    hrs-text {
        --display: block;
    }

    @media (min-width: 768px) {

        .c_splash--app_name {
            margin-top: .5rem;
        }

        .c_splash--logo_container {
            height: 13vh;
            min-height: 6.25rem;
        }
        
        .c_splash--logo {
            height: 13vh;
            min-height: 6.25rem;
        }
    
        .c_splash--signin_image {
            height: 37vh;
            min-height: 15.5rem;    
        }

        .c_splash--form {
            flex-grow: 1;
        }

        .c_splash--contact {
            margin-top: 1.25rem;
            flex-grow: 0;
        }

        hrs-form-item {
            &:nth-child(2) {
                margin-top: 1.5rem;
            }
    
            &:nth-child(3) {
                margin-top: 3.5rem;
            }
        }    
    }
}
