@import "c_button";
/* ######################################################################################### %
                                                                        C_FOOTER
% ######################################################################################### */
@mixin c_footer {
    display: flex;
    justify-content: space-between;
    padding: 1em;
    width: 100%;
    height: auto;
    min-height: 5em;
    background-color: var(--white);
    box-shadow: 0 1px 11px -6px var(--black);

    .c_call--button,
    .c_footer--button {
        --background: var(--color-primary);
        --border-radius: 5px;
        --button-width: 7.1em;
        height: 2.7em;
        text-align: center;
        margin-left: auto;
        border-radius: 5px;
        box-shadow: var(--shadow-sm);

        &-back {
            --border-radius: 5px;
            --padding-start: 0;
            --padding-end: 0.2em;
            height: 2.7em;
            width: 2.8em;
            border-radius: 5px;
            box-shadow: var(--shadow-sm);
            @include u_ion-override-host-background-color;
            --override-background-color: var(--color-primary);

        }
        &-cancel { // .c_footer--button-cancel
            margin-left: 0;
            text-transform: uppercase;
        }
        &-save { // .c_footer--button-save
            text-transform: uppercase;
        }
        &-left {
            --button-width: 5.9em;
            height: 2.7em;
        }

        /* ######################################################################################### %
                                                                BUTTON VARIANTS FOR CALL VIEWS
        % ######################################################################################### */
        &-call {
            @include u_ion-override-host-background-color;//background-color: transparent; // override background color set in the above mixin to make sure the parent element doesn't get the dark blue background or else the rounded corners will dissapear
            --override-background-color: var(--color-primary);
        }
        &-end {
            @include u_ion-override-host-background-color;
            --override-background-color: var(--color-error);
        }
        .c_call--icon {
            --color: var(--white);
            color: var(--white);

            &-dial { font-size: 2.2em; }
            .mute { font-size: 30px; }
            .speaker { font-size: 40px; }
            .mute, .speaker { color: var(--color-primary); }
        }

        .settings {
            background-color: transparent;
            border: 1px solid var(--color-primary);
        }
    }

    .c_footer--text {
        font-size: 1.2em;
    }
    // Checkmark icon for the 'Save' button
    .c_footer--icon-save {
        margin-right: 10px;
        content: url(/../../../../assets/icon/icon-save.svg);
    }
}

.c_footer {@include c_footer;}
