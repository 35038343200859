/* ######################################################################################### %
BASIC FORM FIELDS:

IMPLEMENTATIONS:
(knowing which pages use these styles are helpful when breaking changes are introduced in order to make updates faster to test):
1 - CCM: add-patient.page.html
2 - CGM: signup.page.html
3 - CGM: pin-entry.page.html
4 - (add your page here)
5 - (add your page here)
6 -

USAGE:
<form class="c_form">
    // basic input field example:
    <ion-item class="c_form--field-group">
        <ion-label
            position="stacked"
            class="c_form--label c_form--label-required"
            >field label</ion-label>
        <ion-input
            position="stacked"
            class="c_form--field c_form--field-basic"
            type="text"
            placeholder="placeholder text..."
        ></ion-input>
        <p class="c_form--error-msg">Put the error message inside of ion-item</p>
    </ion-item>

    // date picker example:
    <ion-item>
        <ion-label
            position="stacked"
            class="c_form--label c_form--label-required"
            >field label</ion-label>
        <ion-datetime
            position="stacked"
            class="c_form--field c_form--field-date"
            placeholder="placeholder text..."
        ></ion-datetime>
        <p class="c_form--error-msg">Put the error message inside of ion-item</p>
    </ion-item>

    // select box example:
    <ion-item>
        <ion-label
            position="stacked"
            class="c_form--label c_form--label-required"
            >field label</ion-label>
        <ion-select
            addShadowPartsToSelectBox // THIS IS A REQUIRED ATTRIBUTE - see reference [1]
            class="c_form--field c_form--field-select"
            position="stacked"
            placeholder="placeholder text..."
        >
            <ion-select-option value=" ">value</ion-select-option>
        </ion-select>
        <p class="c_form--error-msg">Put the error message inside of ion-item</p>
    </ion-item>
</form>

References:
[1] addShadowPartsToSelectBox is a custom attribute that should only be added to <ion-select> elements.
It adds the "part" attribute to the icon and placeholder text that exist in <ion-select>'s shadow dom,
which can't be styled with traditional CSS. As of Ionic4 CSS Variables (custom properties) are not available to style
the opacity and to style the icon. However, --placeholder-opacity is added in Ionic5. At some point Ionic will have the
custom properties we need and we can remove this directive and replace it with CSS.

STATUS: Only 3 form elements have been introduced (ion-input, ion-select, and ion-datetime)
As new form fields like text-areas are added, they should be added to this file. Radio buttons and checkboxes
have their own files (c_checkbox.scss and c_radio.scss).

% ######################################################################################### */
.c_form {
    // 'c_form--field-group' gets applied to the <ion-select class="c_form--field-group"> element.
    // It's the wrapper around each individual form field
    .c_form--field-group {
        min-height: 94px;
        --full-highlight-height: 0; // remove bottom red border from android phones
    }
    // 'c_form--label' gets applied to <ion-label class="c_form--label">
    .c_form--label {
        display: flex; // needed for required fields that have an asterisk to align properly
        align-items: center;
        margin: 0;
        max-height: 18px; // this might need to be adjusted in the future for longer labels that go 2 lines
        font-weight: normal;
        font-size: 1rem;
        line-height: 1.125;
        --color: var(--color-primary-dark);
        --background: transparent;
    }
    // 'c_form--label--required' is a modifier class and adds the red asterisk to required fields
    // gets applied to <ion-label class="c_form--label c_form--label-required">
    .c_form--label-required {
        &:before {
            margin-top: 2px;
            padding-right: 3px;
            font-size: 1.75rem;
            font-weight: bold;
            color: var(--color-error);
            content: "*";
        }
    }
    // 'c_form--field' gets applied to all form fields - it applies basic form field styles.
    // This includes <ion-input>, <ion-select>, <ion-datetime>, and others
    .c_form--field {
        max-height: 28px;
        @supports (-webkit-touch-callout: none) {
            min-height: 30px; //ios is wanting some extra height.
        }
        margin: 0;
        padding: 0;
        border-bottom: 1px solid var(--gray-5);
        font-size: 1.25rem;
        line-height: 1.4;
        text-align: center;
        background: transparent;
        position: relative;
        --color: var(--color-accent);
        //placeholder text styles
        --placeholder-color: var(--gray-6);
        --placeholder-font-style: italic;
        --placeholder-font-weight: 400;
        --placeholder-opacity: 1;
        ::placeholder {
            opacity: 1;
        }

        .c_form--button-show_pass,
        .c_form--button-hide_pass {
            @include u_ion-override-host-background-color;
            position: absolute;
                right: 0;
                bottom: 0;
            width: 2.1rem;
        }
        .c_form--button-show_pass { background: no-repeat center url(/assets/icon/icon-show_password.svg); }
        .c_form--button-hide_pass { background: no-repeat center url(/assets/icon/icon-hide_password.svg); }
    }
    // <ion-select class="c_form--field c_form--field-select">
    .c_form--field-select {
        position: relative;
        overflow: visible;
        &:after {
            position: absolute;
            right: 2px;
            top: 0;
            content: "";
            background: url(/../../assets/icon/icon-expand_options.svg) no-repeat top right;
            background-size: 16px 25px;
            width: 16px;
            height: 25px;
            cursor: pointer;
        }
    }
    // adds the calendar icon to <ion-datetime class="c_form--field c_form--field-date">
    .c_form--field-date {
        position: relative;
        overflow: visible;
        &:after {
            position: absolute;
            right: 2px;
            top: -3px;
            content: "";
            background: url(/../../assets/icon/icon-date_picker.svg) no-repeat top right;
            background-size: 26px 28px;
            width: 26px;
            height: 28px;
            cursor: pointer;
        }
    }
    // Styles for the faux placeholder text for ion-datetime and ion-select
    // Classes on elements in the shadow dom like "select-text" and "select-placeholder" cannot be styled directly
    // However, certain properties that are set to 'inherit' will actually inherit from elements outside the shadow dom https://stackoverflow.com/questions/49709676/light-dom-style-leaking-into-shadow-dom
    .c_form--field-select,
    .c_form--field-date {
        --placeholder-opacity: 1; // note: this does nothing in ionic4, but will work when we upgrade to ionic5.
    }
    // ion-select and ion-datetime are not typical html inputs and
    // therefore don't use 'placeholder' attribute and
    // thus must style their placeholder text using their specific classes thru inheritance
    .item-has-placeholder {
        .c_form--field-select,
        .c_form--field-date {
            color: var(--gray-6);
            font-style: italic;
            font-weight: 400;
        }
    }
    .item-has-value {
        .c_form--field-select,
        .c_form--field-date {
            color: var(--color-accent);
            font-style: normal;
            font-weight: bold;
        }
    }
    // opacity of the placeholder text and the display of the icon cannot be styled thru parent inheritance (their default value is not 'inherit') so using shadow root's part attribute (which is applied through a custom Angular attribute directive)
    //currently  ::part() is not supported in all browsers (specifically ios < 13.5) thus the addShadowPartsToSelectBox angular directive also effectively does the same thing inline styles for older browsers
    // thus, if you make any changes here, you must also do the same in selectbox.directive.ts
    ::part(selectBoxPlaceholder) {
        opacity: 1;
    }
    ::part(selectBoxIcon) {
        display: none;
    }
    // focus state
    //first remove the default gray background that ionic applies
    ion-item {
        --background-focused: transparent;
        --background-hover: transparent;
        --background-activated: transparent;
    }
     // ion class that get attached to <ion-item> when focused
     // ion-datetime uses "ion-focused" to <ion-item>
     // ion-input attaches "item-has-focus" to <ion-item>
    .ion-focused,
    .item-has-focus {
        .c_form--label {
            font-weight: bold;
        }
        .c_form--field {
            border-width: 2px;
            border-color: var(--color-primary-dark);
        }
    }
    // error state
    .c_form--field {
        &.ion-invalid.ion-touched {
            border-color: var(--color-error);

        }
    }
    .c_form--error-msg {
        margin: 3px 0 0 0;
        color: var(--color-error);
        font-size: 1rem;
        line-height: 1.2;
        font-weight: normal;
    }
    // For when a form field is pre-populated and not selectable. 
    // Example: 
    // <div class="c_form--static_group">
    //     <p class="c_form--static_label">label</p>
    //     <p class="c_form--static_value">value</p>
    // </div>
    .c_form--static_group {
        display: flex;
        justify-content: space-between;
        width: 100%;
        border-bottom: 1px solid var(--gray-5);
        align-items: flex-end;
    }
    .c_form--static_label {
        margin-right: 10px;
        font-size: 1rem;
        font-weight: 400;
        color: var(--color-accent);
    }
    .c_form--static_value {
        font-size: 1.25rem;
        font-weight: bold;
        color: var(--color-accent);
    }
}


// Review page
// c_form--review
// the review page is a list of all the form fields and the values the user entered
// it appears at the end of some multi-step forms, like the Add Patient modal in CCM
.c_form--review {
    &.list-inset {
        margin-top: 0;
        margin-bottom: 30px;
    }
    .c_form--review-item {
            display: flex;
            justify-content: flex-start;
            --padding-top: 5px;
            --padding-bottom: 2px;
            --color: var(--color-accent);
            --border-color: var(--gray-1);
            --ion-safe-area-left: 0;
            --ion-safe-area-right: 0;
            --min-height: 0;
        }
        .c_form--review-label {
            font-size: 14px;
            font-weight: 800;
            margin: 0;
            max-width: 135px;
            @media (min-width: 400px) {
                max-width: 40%;
            }
        }
        .c_form--review-value {
            margin: 0;
            font-size: 16px;
            font-weight: 400;
        }
}