@import "../../../theme/variables";

.metrics-card {
    border-top-left-radius: .5rem;
    border-top-right-radius: .5rem;
}

.metrics-card--header_title {
    background-color: var(--color-accent);
    color: var(--white);
    font-size: 1.2rem;
    letter-spacing: .04rem;
    font-weight: bold;
    text-align: center;
}

.metrics_list--metric_row {
    width: 100%;
    min-height: 45px;
    border-radius: .5rem;
    border: 2px solid;
    padding: 0 .2em;
    ion-col {
        display: flex;
        align-items: center;
        font-weight: bold;
        font-size: 1em;
    }
    .metric-label {
        justify-content: flex-start;
    }
    .metric-value {
        justify-content: flex-end;
        .metric_value--icon {
            margin-left: .4em;
            font-size: 1.6em;
        }
    }

    &.has-no_readings {
        --border-color: var(--color-risk-border);
        --color: var(--color-risk-border);
    }
}

.metric-label {
    text-align: left;
    font-weight: 600;
}

.metric-value {
    display: flex;
    align-items: center;

    .metric_value--reading {
        letter-spacing: .03rem;
        position: relative;

        &.is-slash_separated-po {
            &:nth-of-type(1) {
                right: -0.3rem;
            }
        }
        &.is-slash_separated-bp {
            &:nth-of-type(1) {
                right: -0.5rem;
            }
            &:nth-of-type(2) {
                right: -0.25rem;
            }
        }
    }

    .metric_value--unit {
        margin-left: .2em;
    }

    .metric_value--icon {
        margin-left: 1rem;
        &:before {
            font-weight: 600;
        }
    }
}

.metrics_list--metric_row {
    &.has-multiple_readings {
        --color-risk-bg: inherit;
        --color-risk-text: inherit;
        background-color: var(--color-risk-bg);
        border-color: var(--color-risk-bg);
        color: var(--color-risk-text);

        .metric_value--icon {
            font-weight: bolder;
        }

        &.highrisk {
            --color-risk-bg: var(--color-error);
            --color-risk-text: var(--white);
        }

        &.medrisk {
            --color-risk-bg: var(--color-risk-medium);
            --color-risk-text: var(--white);
        }

        &.lowrisk {
            --color-risk-bg: var(--color-success);
            --color-risk-text: var(--white);
        }
    }

    &.has-no_readings {
        border-color: var(--color-risk-border);
        color: var(--color-risk-border);
        
        &:active {
            color: var(--white);
            border-color: var(--gray-4);
            background-color: var(--gray-4);
        }
    }

    &:not(.is-open),
    &:not(.has-shadow) {
        box-shadow: none;
        transform: none;
        transition: all 100ms ease-in-out;
        transition-property: box-shadow, transform;
    }

    &.has-shadow {
        box-shadow: var(--shadow-md);
        transform: scale3d(1.02, 1, 1.1);
        transition: all 350ms ease-in-out;
        -webkit-filter: blur(radius);
        -webkit-transform: translateZ(0);
        transition-property: box-shadow, transform;

        &:active {
            box-shadow: var(--shadow-sm);
            transition: box-shadow 200ms ease-out;
        }
    }
    &[class*="Survey"] {
        --color-risk-border: var(--color-risk-border);
        --color-risk-text: var(--color-risk-border);
        --color-risk-bg: var(--white);
        border: 2px solid var(--color-risk-border);
        background-color: var(--color-risk-bg);
        color: var(--color-risk-text);

        &[class*="highrisk"] {
            --color-risk-border: var(--color-error);
            --color-risk-text: var(--color-error);
            &:active {
                background-color: var(--color-error);
                color: var(--white);
                border: none;
                .metrics_list--survey_arrow {
                    color: var(--white);
                }
            }
        }

        &[class*="medrisk"] {
            --color-risk-border: var(--color-risk-medium);
            --color-risk-text: var(--color-risk-medium);
            &:active {
                background-color: var(--color-risk-medium);
                color: var(--white);
                border: none;
                .metrics_list--survey_arrow {
                    color: var(--white);
                }
            }
        }

        &[class*="lowrisk"] {
            --color-risk-border: var(--color-success);
            --color-risk-text: var(--color-success);
            &:active {
                background-color: var(--color-success);
                color: var(--white);
                border: none;
                .metrics_list--survey_arrow {
                    color: var(--white);
                }
            }
        }

        &.has-multiple_readings {
            border: none;
            &[class*="highrisk"] {
                --color-risk-bg: var(--color-error);
                --color-risk-text: var(--white);
            }

            &[class*="medrisk"] {
                --color-risk-bg: var(--color-risk-medium);
                --color-risk-text: var(--white);
            }

            &[class*="lowrisk"] {
                --color-risk-bg: var(--color-success);
                --color-risk-text: var(--white);
            }
        }

        .metrics_list--survey_value_label {
            margin-left: auto;
            font-weight: 400;
        }

        .metrics_list--survey_arrow {
            color: var(--color-risk-text);
            &:before {
                font-size: 2rem;
                font-weight: bolder;
            }
        }
    }
}

.metrics_accordion--inner {
    padding: 0 .4rem;

    .metrics_accordion--item {
        padding: .2rem .75rem;
        justify-content: space-between;
        font-weight: bold;
        text-align: left;
        font-size: .9em;

        .metrics_accordion--entry_container {
            width: fit-content;
            margin-left: auto;
        }

        &:first-of-type {
            .metrics_accordion--entry_container:first-of-type {
                &:first-of-type {
                    .metrics_accordion--entry_bit {
                        font-size: .9rem;
                    }
                }
            }
        }

        .metrics_accordion--entry_date,
        .metrics_accordion--entry_time {
            letter-spacing: .01rem;
        }

        .metrics_accordion--entry_reading {
            letter-spacing: 0;
            margin-right: 0.1rem;
        }

        .metrics_accordion--entry_bit {
            position: relative;
            &.is-slash_separated-po {
                &:nth-of-type(1) {
                    right: -0.2rem;
                }
            }
            &.is-slash_separated-bp {
                &:nth-of-type(1) {
                    right: -0.35rem;
                }
                &:nth-of-type(2) {
                    right: -0.15rem;
                }
            }
        }

        .metrics_accordion--entry_time {
            font-size: 0.65rem;
            font-weight: bold;
        }

        &:first-of-type {
            border-radius: .3rem .3rem 0 0;
        }

        &:nth-of-type(odd) {
            background-color: var(--gray-1);
        }

        &:nth-of-type(even) {
            border-left: .25rem solid var(--gray-1);
            border-right: .25rem solid var(--gray-1);
            padding-left: .5rem;
            .metrics_accordion--entry_container {
                position: relative;
                left: 0.26rem;
            }
            .metrics_accordion--entry-medication {
                position: relative;
                left: 0.25rem;
            }
        }

        &:last-of-type {
            border-bottom: .25rem solid var(--gray-1);
            border-radius: 0 0 .3rem .3rem;
        }
    }

    .metrics_list--btn-show_more {
        width: 100%;
        margin: 0 .4rem 0 0;
        border-radius: 0 0 .4rem .4rem;
        background-color: var(--color-primary);
        font-weight: bold;
    }

    .metrics_accordion--spinner {
        margin-top: .5rem;
        width: 100%;
    }
}

.metrics_accordion {
    opacity: 0;
    &.is-open {
        opacity: 1;
        transition: opacity 300ms linear;
        margin-bottom: .5rem !important;
    }
}

.resetMetricButton {
    align-self: center;
    background-color: var(--color-primary);
    font-weight: bold;
    height: 3rem;
    margin: 0 0 2rem 0;
    width: 100%;
    box-shadow: var(--shadow-md);
    text-transform: capitalize;
}

.button-md,
.button-ios {
    /* These rules override some default styling on ionic Material Design buttons
        which applies UPPERCASE to whatever the text value is */
    font-size: 1.5rem;
    text-transform: none;
}
