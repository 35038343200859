/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

// Custom CSS and variables
@import "./theme/variables";
@import "../../../libs/pattern-library/common-styles";
@import url(../../../libs/pattern-library/custom-properties.scss);

// App
@import "./app/app.component";
@import "app/patient-list/patient-list.page";
@import "app/patient/patient-profile.page";
@import "app/patient/device/add-device.page";
@import "app/patient/reset-metrics/reset-metrics.page";
@import "app/patient/patient-status-change/patient-status-change";
@import "app/patient/patient-surveys/patient-surveys.page";
@import "app/patient/historical-data/historical-data.page";

// Libs
@import "../../../libs/components/src/accordionItem/accordionItem";

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

:root {
    @include hrs-custom-properties;
}

// padding on top is for all device versions -fixes issue of top being cut off after orientation change on iPhoneX too
body {
    .ios ion-nav > .ion-page > ion-header > .toolbar.statusbar-padding:first-child {
        padding-top: 1em;
    }
}

/* ######################################################################################### %
                                      ICON PATHS FOR PATTERN LIBRARY
% ######################################################################################### */
.c_footer--icon-back { content: url(/assets/icon/icon-arrow_back-white.svg); }
.c_chat--icon-send { content: url(/assets/icon/icon-send_email.svg); }
.c_app_header--icon-back {
    content: url(/assets/icon/icon-arrow_back.svg);
    &.is-modal { content: url(/assets/icon/icon-arrow_back-black.svg); }
}
