accordionItem {
  .expand-wrapper {
    transition: max-height 0.4s ease-in-out;
    overflow: hidden;
    height: auto;
  }

  .collapsed {
    height: 0 !important;
  }
}
