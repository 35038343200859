@import "../../../theme/variables";

.noDevicesAvailable {
    text-align: center;
}

.c_add_device--select {
    --border-color: transparent;
    width: 100vw;
    border: 1px solid var(--color-accent);
}
