@import '../../theme/variables';
@import "../../../../../libs/pattern-library/utility-mixins";

.c_patient_profile--toolbar-btn {
    --ion-toolbar-color: var(--black);
}

.cards-bg {

    .cards-list-demo {
        z-index: 3;
        margin-left: 3%;
        margin-right: 3%;
        .careplan--btn {
            padding: 1em;
            .c_button-center {
                --background: var(--color-primary);
                height: 2.5em;
                width: 15.5em;
                margin: auto;
                font-weight: 700;
                text-transform: capitalize;
            }
        }
    }
}

.c_alert-careplan_success {
    .alert-wrapper {
        --ion-background-color-rgb: 70, 190, 138;
        position: absolute;
        top: 9vh;
        box-shadow: var(--shadow-md);

        .alert-title {
            margin-top: 0;
            color: var(--white);
            font-weight: 600;
            font-size: 2em;
        }
        .alert-message {
            color: var(--white);
            text-align: left;
            font-weight: 400;
            font-size: 1.1em;
        }
    }
}

.patient_detail--row {
    display: grid;
    grid-template-columns: 10.5em 16em;
    border-bottom: 2px solid var(--color-menu-item-border);
    text-align: left;

    .patientDetailTitle {
        flex-basis: 40%;
    }

    .patientDetail {
        margin-top: 10px;
        text-align: left;
    }
    h5, h6 {
        margin-bottom: 0;
    }
}

.c_patient_profile--conditions {
    background-color: var(--color-accent);
}

.headerTitle {
    text-align: center;
    font-weight: bold;
}

.patientprofile-communications {
    text-align: center;
    margin-bottom: 25px;

    .communicationsIcon { // voice call, video call, chat buttons
        min-width: 47.25px;
        @include u_ion-override-host-background-color;
        background-color: transparent; // override background color set in the above mixin to make sure the parent element doesn't get the dark blue background or else the rounded corners will dissapear
        --override-background-color: var(--color-primary);
    }
}

.quickNotes {
    .input-cover {
        border: 1px solid var(--gray-8);
    }
    // quick notes save button container - used to center the button and add top/bottom margins
    .c_buttons {
        justify-content: center;
        margin: 1rem 0;
    }
    .editQuickNoteButton {
        @include u_ion-override-host-background-color;
        --override-background-color: var(--color-success);
        width: auto;
        padding-left: 8px;
        padding-right: 8px;
        margin: 0;
        text-transform: capitalize;
        font-weight: bold;
    }
}

.addOrEditDevice {
    margin-bottom: 20px;
    text-align: center;

    .addOrEditDevice--btn {
        @include u_ion-override-host-background-color;
        --override-background-color: var(--white);
        @include u_ion-override-host-text-color;
        --override-text-color: var(--black);
        box-shadow: var(--shadow-sm);
        border-radius: 5px;
    }

    .button-inner {
        color: var(--color-accent);
        background: var(--white);
        font-size: 12px;
    }

    .button-default-ios {
        box-shadow: var(--shadow-md);
        height: 2.3em;
    }
}

.profileList {
    margin: 8px;

    .image-card {
        border-radius: 15px;
        padding: 20px 3%;

        &.preactivated {
            background: var(--color-risk-none);
        }
        &.highRisk {
            background-color: var(--color-error);
        }
        &.medRisk {
            background-color: var(--color-risk-medium);
        }
        &.lowRisk {
            background-color: var(--color-success);
        }
        img {
            width: 12rem;
            height: 12rem;
            margin: 0 auto 20px;
            padding: 5px;
            background: var(--white);
            border-radius: 500px;
        }
        ion-avatar {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 12rem;
            height: 12rem;
            margin: 0 auto 20px;
            padding: 5px;
            background: var(--white);
            border-radius: 500px;
            text-align: center;
            font-size: 6.5em;
            font-weight: bolder;
            line-height: 12rem;
            color: var(--color-accent);
        }
    }

    .patient-status-btn-group {
        text-align: center;
        width: 100%;
        border-collapse: separate;
        margin-bottom: 1em;

        ion-button {
            color: var(--color-accent);
            background-color: var(--white);
            padding: 0;
            border: 1px solid var(--gray-8);
            margin: 0;
            height: 4em;
            width: calc(100%/3);
        }

        ion-icon {
            font-size: 1.20rem;
        }

        ion-label {
            display: block;
            font-size: .75rem;
            &.md { font-size: .55rem }
            white-space: break-spaces;
        }

        .pause-btn {
            border-radius: 5px 0px 0px 5px;
        }

        .discharge-btn {
            border-radius: 0px 5px 5px 0px;
        }

        .button-md {
            ion-label {
                font-size: .9rem;
            }
        }

        .ion-ios-close {
            font-size: 2.5em;
        }
    }

    .patient-last-activity-info {
        display: flex;

        h5 {
            color: var(--white);
            align-self: flex-start;
            font-size: 1em;
        }

        h6 {
            color: var(--white);
            align-self: flex-end;
            margin-left: auto;
            font-size: 1em;
            margin-top: 11px;
        }
    }

    .highrisk {
        border-color: var(--color-error);
        color: var(--color-error);
        background-color: transparent;
        &:active {
            background-color: var(--color-error);
            color: var(--white);
        }
    }

    .medrisk {
        border-color: var(--color-risk-medium);
        color: var(--color-risk-medium);
        background-color: transparent;
        &:active {
            background-color: var(--color-risk-medium);
            color: var(--white);
        }
    }

    .lowrisk {
        border-color: var(--color-success);
        color: var(--color-success);
        background-color: transparent;
        &:active {
            background-color: var(--color-success);
            color: var(--white);
        }
    }

    .highriskSurvey {
        color: var(--white);
        background-color: var(--color-error);
        &:active{
            border: 1px solid var(--color-error);
            background-color: transparent;
            color: var(--color-error);
        }
    }

    .medriskSurvey {
        color: var(--white);
        background-color: var(--color-risk-medium);
        &:active, &:visited {
            border: 1px solid var(--color-risk-medium);
            background-color: transparent;
            color: var(--color-risk-medium);
        }
    }

    .lowriskSurvey {
        color: var(--white);
        background-color: var(--color-success);
        &:active {
            border: 1px solid var(--color-success);
            background-color: transparent;
            color: var(--color-success);
        }
    }

    .patientprofile-communications {
        margin-bottom: 3px;
        margin-top: 3px;

        .communicationsIcon {
            background: var(--color-primary);
            border: 1px solid var(--color-primary);
            border-radius: 3px;
            color: var(--white);
            margin: 5px 2px 10px;

            .icon {
                font-size: 1.5em;
            }
        }
    }

    .patient-name {
        font-size: 20px;
        font-weight: bold;
        margin-top: 15px;
    }

    .patientDetailTitle {
        margin-top: 10px;
        font-weight: 700;
    }

    .patientDetail {
        text-transform: capitalize;

        .connected {
            color: var(--color-success);
            margin-right: 5px;
        }

        .notConnected {
            color: var(--color-error);
            margin-right: 5px;
        }
    }

    .devices {
        text-align: center;
        color: var(--color-accent);
    }

    .badge {
        margin: .1em;
    }

    .noConditions {
        text-align: center;
    }

    .buttonOne {
        border: 1px solid var(--color-error);
        color: var(--color-error);
        .notReviewed {
            color: var(--color-error);
            margin-right: 5px;
        }
    }

    .buttonTwo {
        border: 1px solid var(--color-success);
        color: var(--color-success);
        .reviewed {
            color: var(--color-success);
            margin-right: 5px;
        }
    }

    .buttonOne,
    .buttonTwo {
        margin: 0;
        text-transform: capitalize;
        font-size: 13px;
        background: var(--white);
        font-weight: bold;
        border-radius: 3px;
        min-width: 170px;

        ion-spinner {
            color: inherit;
        }
    }
}

.surveys-card {
    min-height: calc(100% - 20px); // to prevent jumping when loading the next day

    .headerTitle {
        background: var(--color-accent);
        color: var(--white);
        display: flex;
        padding: 20px 12px 20px 12px;

        .surveys_card_header--back-arrow {
            position: absolute;
            font-size: 20px;
            font-weight: var(--black);
        }

        .surveys_card_header--title {
            width: 100%;
            font-size: 24px;
            padding-left: 12px;
            line-height: 29px;
        }
    }
}

.c_patient_profile {
    &--reviewed {
        &_checkmark {
            color: var(--white);
            display: block;
            width: 100%;
        }

        &_buttons {
            width: 100%;
            display: inline-flex;
        }

        &_state:nth-of-type(1) {
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
        }

        &_state:nth-of-type(2) {
            border-right: none;
            border-left: none;
        }

        &_state:nth-of-type(3) {
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
        }

        &_state {
            color: var(--black);
            white-space: normal;
            align-items: center;
            border: 1px solid var(--black);
            min-height: 3.25em;
            height: auto;
            min-width: 33%;
            margin: 0;
            font-size: 1em;

            &.posting {
                color: var(--gray);
            }

            &.reviewed.state_selected {
                background-color: var(--color-risk-low);
                padding-top: .75em;
                padding-bottom: .75em;
            }

            &.in_progress.state_selected {
                background-color: var(--color-risk-medium);
            }

            &.non_reviewed.state_selected {
                background-color: var(--color-risk-high);
            }

            .reviewed_btn--content_wrapper {
                align-self: center;
                max-width: 100%;
            }
        }

        &_spinner {
            position: absolute;
            width: 40%;
            top: .75em;
            left: 30%;
        }
    }
}
